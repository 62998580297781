import React, { Fragment, useEffect, useRef, useState } from 'react'
import "../style/company.less"
import Header from '../components/header'
import Footer from '../components/footer'
import bigImg from "../images/company/bigimg.png"
import logo from "../images/company/logo.png"
import { navigate } from 'gatsby'
import textImg from "../images/company/text_img.png"
import add from "../images/company/add.png"
import point from "../images/home/point.png"
import Fade from "react-reveal/Fade"
export default function Company() {
    const bigTitle = useRef(null)
    const listDom = useRef(null)
    const [titleShadow, setTitleShadow] = useState(false)
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            setTitleShadow(entries[0].isIntersecting)
            if(entries[1]?.isIntersecting){
                    window.addEventListener("wheel",getWheel)
            }else{
                window.removeEventListener("wheel",getWheel)
            }
        }, {
            
        }) 
        observer.observe(bigTitle.current)
        observer.observe(listDom.current)
    })
    const ourStoryList = [
        { id: 0, title: "Now" },
        { id: 1, title: "Since2003" },
        { id: 2, title: "Now" },
        { id: 3, title: "Now" }
    ]
    const getWheel =  (e)=>{
        var step = 200;  
        if(e.deltaY<0){
            
                listDom.current.scrollLeft -=step
            
            
        }else{
                listDom.current.scrollLeft +=step
            
        }
    }
    let firstMove ;
    let listScroll;
    //Mouse Press
    const slideList = (e)=>{
        console.log(e.pageX,listDom.current.scrollLeft);
         firstMove  = e.pageX
         listScroll = listDom.current.scrollLeft
        listDom.current.addEventListener("mousemove",mouseMovelist)
    }
    const removeListener = ()=>{
        listDom.current.removeEventListener("mousemove",mouseMovelist)
    }
    // Move the mouse over the element
    const mouseMovelist = (e)=>{
        let moveScroll = firstMove - e.pageX
        let scroll = listScroll +moveScroll
        listDom.current.scrollLeft = scroll
    }
    return (
        <div className='company'>
            <div className="about">
                <Header />
                <div className='about_content'>
                    <Fade distance={"20%"} top duration={1300} top>
                        <h3>About</h3>
                    </Fade>
                    <p className='text1'>Building Enterprise
                        Application Frameworks and
                        Products for decades</p>
                    <p className='text2'>As one of Canada’s longest standing AI companies, we are a team
                        of entrepreneurs, data scientists, researchers, and programmers
                        who have been building Enterprise Application Frameworks and
                        Products for decades.</p>
                    <div onClick={() => navigate("/contactUs")} className='button'>Get Started</div>
                </div>
                <div className='img'>
                    <img src={logo} alt="" />
                </div>
            </div>
            <div className='mission'>
                <h1 ref={bigTitle} className={titleShadow ? "shadow" : ""}>Mission</h1>
                <Fade distance={"20%"} top duration={1300} top>
                    <h3>Mission</h3>
                </Fade>
                <p>Embracing and contributing to open-sourcas a future focus of the companye</p>
            </div>
            <div className="big_img">
                <img src={bigImg} alt="" />
                <div>sightline</div>
            </div>
            <div className="number_count">
                <div className='backgroundImg'>
                    <img src={textImg} alt="" />
                </div>
                <ul className='number_top'>
                    <li>
                        <div>2003</div>
                        <p>year founded</p>
                    </li>
                    <li>
                        <div>34
                            {/* <img src={add} /> */}
                            <span>+</span>
                        </div>
                        <p>cooperative partners</p>
                    </li>
                </ul>
                <ul className='number_bottom'>
                    <li>
                        <div>300
                            {/* <img src={add} /> */}
                            <span>+</span>
                        </div>
                        <p>successful cases</p>
                    </li>
                    <li>
                        <div>40</div>
                        <p>development and service team</p>
                    </li>
                    <li>
                        <div>18</div>
                        <p>countries and cities</p>
                    </li>
                </ul>
            </div>
            <div className='our_story'>
                <div className='our_story_title'>
                    <Fade distance={"20%"} top duration={1300} top>
                        <h3>Our Story</h3>
                    </Fade>
                    <p>Building Enterprise Application Frameworks and Products for decades.</p>
                </div>
                <span className='big_text'>
                    <span>As one of Canada’s longest standing AI companies</span>
                    <span>As one of Canada’s longest standing AI companies</span>
                </span>
                <div className='our_story_text'>
                    <Fade distance={"20%"} top duration={1300} top>
                        <h3>Committed to open-source data research </h3>
                    </Fade>
                    <p>We are a team of entrepreneurs, data scientists, researchers, and programmers who have been building Enterprise Application Frameworks and Products for decades.</p>
                </div>
                <div className='dashed'>
                    <img src={point} alt="" />
                    <img src={point} alt="" />
                    <img src={point} alt="" />
                    <img src={point} alt="" />
                </div>

                <div className='our_story_list scroll' onMouseDown={slideList} onMouseUp={removeListener}  ref={listDom}>
                    <div className='phone_dashed'>
                        <img className='first_point' src={point} alt="" />
                        {
                            [0, 1, 2, 3].map((item, index) => {
                                return (
                                    <div key={index} className='line'>
                                        <img src={point} alt="" />
                                    </div>
                                )
                            })
                        }
                    </div>
                    <ul >
                        {
                            ourStoryList.map(item => {
                                return (
                                    <li key={item.id}>
                                        <h6>{item.title}</h6>
                                        <h3>The release of “DTaaS 2023” in January</h3>
                                        <p>Our platform enables broad data collaboration to solve complex problems that require data silos to be broken down - all within a set </p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    )
}
